import { AnimatePresence, motion, Transition } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import React, { Children, FC, useEffect, useState } from 'react'

const swipeConfidenceThreshold = 10000

const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

const variants = {
  enter: (direction: number) => ({
    zIndex: 0,
    x: direction > 0 ? 800 : -800,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 800 : -800,
    opacity: 0,
  }),
}

const transition: Transition = {
  x: { type: 'spring', stiffness: 300, damping: 30 },
  opacity: { duration: 0.2 },
}

const dragConstraints = { left: 0, right: 0 }
let exitTimeoutId: ReturnType<typeof setTimeout>

export const SlideShow: FC = ({ children }) => {
  const { ref, inView } = useInView()
  const slides = Children.toArray(children)
  const total = slides.length
  const [[slide, direction], setSlide] = useState([0, 0])
  const slideIndex = wrap(0, total, slide)
  const paginate = (newDirection: number) => {
    if (!inView) return
    clearTimeout(exitTimeoutId)
    exitTimeoutId = setTimeout(() => {
      setSlide(([currentSlide]) => [currentSlide + newDirection, newDirection])
    }, 5000)
  }
  useEffect(() => {
    paginate(1)
    return () => {
      clearTimeout(exitTimeoutId)
    }
  }, [inView])
  return (
    <div className="relative h-full">
      <AnimatePresence exitBeforeEnter custom={direction} initial={false} onExitComplete={() => paginate(1)}>
        <motion.div
          ref={ref}
          key={`testimonial-${slide}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          custom={direction}
          transition={transition}
          drag="x"
          dragConstraints={dragConstraints}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
        >
          {slides[slideIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
