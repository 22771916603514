import React, { FC } from 'react'
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel'

interface ApplyNowProps {
  productId: string
}
export const ApplyNow: FC<ApplyNowProps> = ({ productId }) => {
  return (
    <a
      onClick={() => fbq('track', 'Lead', { content_name: productId })}
      className="cta-button block md:inline-block md:text-lg font-semibold"
      href="https://app.inszu.com/about-you"
    >
      Apply now
    </a>
  )
}
