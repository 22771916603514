export default [
  {
    question: 'How long does it take to apply?',
    answer:
      '5-15 mins. The process is as follows: Complete the 7 step questionnaire to determine which options you will be medically eligible for. We will display your best offers, and you can adjust the death benefit to match a monthly premium that fits within your financial capabilities. From there, you will complete your application submission and be provided an electronic copy for your records upon submission.',
  },
  {
    question: 'How quickly will my beneficiaries get paid?',
    answer:
      '7-30 days. This process can be challenging if your beneficiaries are unaware of your policy # and do not have the proper documents to provide the carrier. At inszu we make sure to make our customer’s lives easier by delivering claim support along with setting up your final wishes guide when your life insurance goes in force. This service is complimentary, and we know from experience that it makes all the difference when you have organized records set up for your family before a death claim.',
  },
  {
    question: 'How much does final expense insurance cost?',
    answer:
      'Prices for final expense insurance vary significantly based on your age and health. Listed here are some pricing examples for reference.',
  },
  {
    question: 'Will I be eligible if I have health conditions?',
    answer:
      'Absolutely. We have coverage options for any pre-existing health conditions. No matter your current health, we can find you an insurance policy that will meet your needs.',
  },
  {
    question: 'Can I exchange an existing policy to get a better rate?',
    answer:
      'Yes, you can! With our complementary reProject service, we will analyze your current plan and provide you with a road map to either improve your current policy and get you a lower monthly premium if you are medically eligible. Many consumers are unaware that their policies have restricted benefits or if they qualify for a better rate. We make the process simple, easy and encourage you to give it a try. Here is an example story from one of our customers.  Fred, age 80, was paying $248.00 monthly for 10,000 of final expense insurance. We analyzed his plan, and he received $1,280 from his previous provider upon replacing his insurance and lowering his monthly premium to $179.82 per month for the same death benefit. This cost savings has helped Fred have more disposable income while still protecting his family.',
  },
  {
    question: 'Do final expense insurance plans provide protection for long term care?',
    answer:
      'Final expense insurance can provide additional living benefits to help protect you from expenses associated with long-term care and other serious illnesses. However, this function is an enhancement that comes with some final expense insurance policies that vary state by state. It is essential to speak to an Inszu professional regarding long term care questions to determine which plan options best suit your needs.',
  },
] as const
