export default [
  {
    user: 'Patrick J.',
    testimonial:
      'I have had the pleasure of working with inszu and cannot speak high enough about the customer service they provided.',
  },
  {
    user: 'Crystal W.',
    testimonial:
      'I’ve had the best experience with the entire inszu team. They have real advisors that took my calls promptly and followed through on helping me get a better policy.',
  },
  {
    user: 'Derek M.',
    testimonial:
      'I was very impressed with how simple and comfortable I felt working with an inszu advisor. Typically I am frustrated with financial service support and with inszu I have enjoyed the process.',
  },
] as const
