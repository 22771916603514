import React, { FC } from 'react'

interface SlideProps {
  text: string
  name: string
}
export const Slide: FC<SlideProps> = ({ text, name }) => {
  return (
    <div className="h-56 md:h-96 mx-8 relative">
      <div className="absolute top-0 left-0 transform -translate-x-8 -translate-y-2">
        <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.88349 10.6497C10.5113 11.4904 11.8252 13.4055 11.8252 16.3949C11.8252 17.983 11.2816 19.3376 10.1942 20.4586C9.1068 21.4862 7.70227 22 5.98058 22C4.16828 22 2.71845 21.3928 1.63107 20.1783C0.543689 18.9639 0 17.3291 0 15.2739C0 10.5096 2.99029 5.41826 8.97087 0L12.3689 2.38217C10.1942 5.83864 8.69903 8.59448 7.88349 10.6497ZM23.5146 10.6497C26.1424 11.4904 27.4563 13.4055 27.4563 16.3949C27.4563 17.983 26.9126 19.3376 25.8252 20.4586C24.7379 21.4862 23.3333 22 21.6116 22C19.7994 22 18.3495 21.3928 17.2621 20.1783C16.1748 18.9639 15.6311 17.3291 15.6311 15.2739C15.6311 10.5096 18.6214 5.41826 24.6019 0L28 2.38217C25.8252 5.83864 24.3301 8.59448 23.5146 10.6497Z"
            fill="#B0B0C3"
          />
        </svg>
      </div>
      <p className="mb-4 text-lg leading-relaxed">{text}</p>
      <span className="text-inszu-teal font-semibold">{name}</span>
    </div>
  )
}
