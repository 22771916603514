import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface ImagesViewProps {}

const transformOptions = { fit: 'cover', cropFocus: 'attention' }
export const ImagesView: FC<ImagesViewProps> = () => {
  return (
    <div className="hidden md:flex flex-row justify-between items-center md:scale-0 overflow-hidden">
      <div className="first-image">
        <StaticImage
          src="../../images/mom-with-kids.jpg"
          alt=""
          className="rounded-full"
          width={215}
          height={170}
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="second-image">
        <StaticImage
          src="../../images/dad-with-daughter.jpg"
          alt=""
          className="rounded-full"
          width={170}
          height={170}
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="third-image">
        <StaticImage
          className="rounded-full"
          width={320}
          height={585}
          src="../../images/grandmom-with-kid.jpg"
          alt=""
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="forth-image">
        <StaticImage
          className="rounded-full"
          src="../../images/dad-with-kids.jpg"
          alt=""
          width={170}
          height={170}
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="fifth-image">
        <StaticImage
          className="rounded-full"
          src="../../images/dad-with-todler.jpg"
          alt=""
          width={228}
          height={142}
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="sixth-image">
        <StaticImage
          className="rounded-full"
          src="../../images/granddad-with-baby.jpg"
          alt=""
          width={315}
          height={178}
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
    </div>
  )
}
