export default [
  {
    state: 'Mississippi',
    funeralCost: 6684,
    endOfLifeCost: 11825,
    totalCost: 18509,
  },
  {
    state: 'Arkansas',
    funeralCost: 6746,
    endOfLifeCost: 11934,
    totalCost: 18680,
  },
  {
    state: 'Oklahoma',
    funeralCost: 6754,
    endOfLifeCost: 11948,
    totalCost: 18702,
  },
  {
    state: 'Missouri',
    funeralCost: 6762,
    endOfLifeCost: 11962,
    totalCost: 18724,
  },
  {
    state: 'New Mexico',
    funeralCost: 6793,
    endOfLifeCost: 12017,
    totalCost: 18810,
  },
  {
    state: 'Tennessee',
    funeralCost: 6886,
    endOfLifeCost: 12182,
    totalCost: 19068,
  },
  {
    state: 'Michigan',
    funeralCost: 6902,
    endOfLifeCost: 12209,
    totalCost: 19111,
  },
  {
    state: 'Kansas',
    funeralCost: 6909,
    endOfLifeCost: 12223,
    totalCost: 19132,
  },
  {
    state: 'Georgia',
    funeralCost: 6925,
    endOfLifeCost: 12250,
    totalCost: 19175,
  },
  {
    state: 'Alabama',
    funeralCost: 6933,
    endOfLifeCost: 12264,
    totalCost: 19197,
  },
  {
    state: 'Wyoming',
    funeralCost: 6933,
    endOfLifeCost: 12264,
    totalCost: 19197,
  },
  {
    state: 'Indiana',
    funeralCost: 6987,
    endOfLifeCost: 12360,
    totalCost: 19347,
  },
  {
    state: 'Iowa',
    funeralCost: 6995,
    endOfLifeCost: 12374,
    totalCost: 19369,
  },
  {
    state: 'Nebraska',
    funeralCost: 7049,
    endOfLifeCost: 12470,
    totalCost: 19519,
  },
  {
    state: 'Ohio',
    funeralCost: 7049,
    endOfLifeCost: 12470,
    totalCost: 19519,
  },
  {
    state: 'Kentucky',
    funeralCost: 7057,
    endOfLifeCost: 12484,
    totalCost: 19541,
  },
  {
    state: 'West Virginia',
    funeralCost: 7072,
    endOfLifeCost: 12511,
    totalCost: 19583,
  },
  {
    state: 'Texas',
    funeralCost: 7103,
    endOfLifeCost: 12566,
    totalCost: 19669,
  },
  {
    state: 'Idaho',
    funeralCost: 7165,
    endOfLifeCost: 12676,
    totalCost: 19841,
  },
  {
    state: 'Louisiana',
    funeralCost: 7290,
    endOfLifeCost: 12896,
    totalCost: 20186,
  },
  {
    state: 'Illinois',
    funeralCost: 7336,
    endOfLifeCost: 12978,
    totalCost: 20314,
  },
  {
    state: 'North Carolina',
    funeralCost: 7367,
    endOfLifeCost: 13003,
    totalCost: 20370,
  },
  {
    state: 'South Carolina',
    funeralCost: 7445,
    endOfLifeCost: 13170,
    totalCost: 20615,
  },
  {
    state: 'Arizona',
    funeralCost: 7530,
    endOfLifeCost: 13321,
    totalCost: 20851,
  },
  {
    state: 'Wisconsin',
    funeralCost: 7554,
    endOfLifeCost: 13363,
    totalCost: 20917,
  },
  {
    state: 'Florida',
    funeralCost: 7600,
    endOfLifeCost: 13445,
    totalCost: 21045,
  },
  {
    state: 'Utah',
    funeralCost: 7639,
    endOfLifeCost: 13514,
    totalCost: 21153,
  },
  {
    state: 'North Dakota',
    funeralCost: 7670,
    endOfLifeCost: 13569,
    totalCost: 21239,
  },
  {
    state: 'South Dakota',
    funeralCost: 7748,
    endOfLifeCost: 13706,
    totalCost: 21454,
  },
  {
    state: 'Virginia',
    funeralCost: 7818,
    endOfLifeCost: 13830,
    totalCost: 21648,
  },
  {
    state: 'Minnesota',
    funeralCost: 7887,
    endOfLifeCost: 13953,
    totalCost: 21840,
  },
  {
    state: 'Pennsylvania',
    funeralCost: 7895,
    endOfLifeCost: 13967,
    totalCost: 21862,
  },
  {
    state: 'Colorado',
    funeralCost: 8198,
    endOfLifeCost: 14503,
    totalCost: 22701,
  },
  {
    state: 'Montana',
    funeralCost: 8229,
    endOfLifeCost: 14681,
    totalCost: 22910,
  },
  {
    state: 'Delaware',
    funeralCost: 8392,
    endOfLifeCost: 14846,
    totalCost: 23238,
  },
  {
    state: 'Nevada',
    funeralCost: 8423,
    endOfLifeCost: 14901,
    totalCost: 23324,
  },
  {
    state: 'New Hampshire',
    funeralCost: 8516,
    endOfLifeCost: 15066,
    totalCost: 23582,
  },
  {
    state: 'Washington',
    funeralCost: 8594,
    endOfLifeCost: 15203,
    totalCost: 23797,
  },
  {
    state: 'Vermont',
    funeralCost: 8889,
    endOfLifeCost: 15725,
    totalCost: 24614,
  },
  {
    state: 'Maine',
    funeralCost: 9122,
    endOfLifeCost: 16137,
    totalCost: 25259,
  },
  {
    state: 'Rhode Island',
    funeralCost: 9269,
    endOfLifeCost: 16398,
    totalCost: 25667,
  },
  {
    state: 'New Jersey',
    funeralCost: 9712,
    endOfLifeCost: 17181,
    totalCost: 26893,
  },
  {
    state: 'Connecticut',
    funeralCost: 9914,
    endOfLifeCost: 17538,
    totalCost: 27452,
  },
  {
    state: 'Maryland',
    funeralCost: 10069,
    endOfLifeCost: 17812,
    totalCost: 27881,
  },
  {
    state: 'Alaska',
    funeralCost: 10084,
    endOfLifeCost: 17840,
    totalCost: 27924,
  },
  {
    state: 'Massachusetts',
    funeralCost: 10216,
    endOfLifeCost: 18073,
    totalCost: 28289,
  },
  {
    state: 'Oregon',
    funeralCost: 10418,
    endOfLifeCost: 18430,
    totalCost: 28848,
  },
  {
    state: 'New York',
    funeralCost: 10799,
    endOfLifeCost: 19103,
    totalCost: 29902,
  },
  {
    state: 'California',
    funeralCost: 11777,
    endOfLifeCost: 20834,
    totalCost: 32611,
  },
  {
    state: 'Hawaii',
    funeralCost: 14975,
    endOfLifeCost: 26492,
    totalCost: 41467,
  },
]
