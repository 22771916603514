import React, { FC, useState, Children, ReactElement } from 'react'

interface QnAProps {
  label: string
  isCollapsed?: boolean
  handleClick?: () => void
}

export const QnA: FC<QnAProps> = ({ label, handleClick, children, isCollapsed }) => {
  return (
    <div className="card mb-2">
      <button
        className={`focus:outline-none w-full hover:opacity-100 ${isCollapsed ? 'opacity-75' : 'opacity-100'}`}
        onClick={handleClick}
      >
        <div className="p-4 flex items-center justify-between">
          <span className={`text-left mr-4 ${isCollapsed ? '' : 'font-semibold'}`}>{label}</span>
          <div className={`ml-4 transform transition duration-75 ${isCollapsed ? 'rotate-0' : 'rotate-45'}`}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 8c0 .6-.4 1-1 1H9v6c0 .6-.4 1-1 1s-1-.4-1-1V9H1c-.6 0-1-.4-1-1s.4-1 1-1h6V1c0-.6.4-1 1-1s1 .4 1 1v6h6c.6 0 1 .4 1 1z"
                fill="#33333D"
              />
            </svg>
          </div>
        </div>
      </button>
      <div
        className={`p-4 text-gray-600 leading-relaxed ${isCollapsed ? 'py-0 hidden' : 'py-4 block'}`}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
    </div>
  )
}

export const FAQ: FC = ({ children }) => {
  const [bindIndex, setBindIndex] = useState(-1)
  const changeItem = (itemIndex: number) => {
    if (itemIndex !== bindIndex) {
      setBindIndex(itemIndex)
    } else {
      setBindIndex(-1)
    }
  }
  const items = Children.toArray(children).filter(item => {
    const reactItem = item as ReactElement<any, typeof QnA>
    return reactItem.type
  }) as ReactElement<any, typeof QnA>[]

  return (
    <>
      {items.map(({ props }, index) => (
        <QnA
          key={`index-${index}`}
          isCollapsed={bindIndex !== index}
          label={props.label}
          handleClick={() => changeItem(index)}
        >
          {props.children}
        </QnA>
      ))}
    </>
  )
}
